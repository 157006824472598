<template>
	<e-select
		track-by="tiers_id"
		label="tiers_rs"
		v-model="searchValue"
		:options="options"
		:placeholder="$t('global.ecrire_pour_rechercher')"
		:loading="isLoading"
		:sortable="false"
		:internal-search="false"
		:disabled="disabled_search"
		@search-change="onSearch"
		@select="searchTiers"
		@input="searchTiers"
		ref="input"
	>
		<template v-if="option.tiers_rs" slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
		<template v-if="option.tiers_rs" slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
		<template slot="noOptions">{{ $t('global.write_to_search') }}</template>
		<template slot="noResult">{{ $t('global.no_result') }}</template>
	</e-select>
</template>

<script>
import _debounce from 'lodash/debounce'
import Navigation from '@/mixins/Navigation'

export default {
	name: 'SearchTiers',
	mixins: [Navigation],
	props: ['preselected', 'presearch', 'disabled'],
    data() {
        return {
			options: [],
			searchValue: null,
			isLoading: false,
			disabled_search: false,
			debouncedSearch: _debounce(this.search, 300),
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			if(this.preselected) {
				this.options = [this.preselected]
				this.searchValue = this.preselected
			}
			else if(this.presearch) {
				this.searchValue = this.presearch
			}

			if(this.disabled) {
				this.disabled_search = this.disabled
			}
		},
		onSearch(search_val) {
			this.debouncedSearch(search_val)
		},
		async search(search_val) {
			this.isLoading = true

			if(search_val == '') {
				this.isLoading = false
				return false
			}

			if(this.$storage.db.tiers) {
				let tiers = await this.$storage.db.t('tiers').then(table => {
					return table.where({
						tiers_archive: 0
					})
				})
				.then(col => {
					return col.filter(elem => {
						var regex = new RegExp(search_val, 'gi');
						return regex.test(elem.tiers_rs) || regex.test(elem.tiers_alias) || (elem.phone_combine && regex.test(elem.phone_combine.join('-')))
					})
				})
				.then(col => {
					return col.limit(30).toArray()
				})

				this.options = tiers.sortBy('tiers_rs')
			}

			this.isLoading = false
		},
		onFocus() {
			this.$refs.input.activate()
			this.$refs.input.$refs.search.click()
		},
		async searchTiers(option) {
			if(option != null) {
				// this.options = [option]
				this.searchValue = {tiers_rs: option.tiers_rs, tiers_postalcode: option.tiers_postalcode}
				this.$emit("update:tiers_selected", option)
			}
			else {
				this.$emit("update:tiers_selected", {})
			}
		},
	},
	watch: {
		preselected(val){
			if(val != undefined) {
				this.options = [val]
				this.searchValue = val
			}
		}, 
		presearch(val) {
			if(val) {
				this.searchValue = {tiers_rs: val}
				this.debouncedSearch(val)
			}
		},
		disabled(val) {
			if(val != null) {
				this.disabled_search = val
			}
		}
	}
}
</script>
